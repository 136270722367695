/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import custom fonts if any */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Apply base styles to the body */
body {
  @apply bg-white text-gray-900 font-sans transition-colors duration-300;
}

/* Dark mode styles */
.dark body {
  @apply bg-gray-900 text-gray-100;
}

/* Styling for headings */
h1, h2, h3, h4, h5, h6 {
  @apply font-bold;
}

/* Styling for links */
a {
  @apply text-blue-600 hover:text-blue-800 transition-colors duration-300;
}

.dark a {
  @apply text-blue-400 hover:text-blue-600;
}

.non-editable-element {
  cursor: default; /* or remove this property */
}