/* Automatically style blog post headings */
.blog-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .blog-subheading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }
  
  .blog-subsubheading {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .blog-paragraph {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  